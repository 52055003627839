/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import Layout from './lib/Layout';
import { useEffect } from 'react';
import Routes from './lib/Routes';
import ScrollToTop from './lib/ScrollToTop';

function getDeviceType() {
  return window.matchMedia("(max-width: 480px)").matches ? 'PHONE' : 'DESKTOP';
}

const App = ({
  setDeviceType,
}) => {

  useEffect(() => {
    setDeviceType(getDeviceType());
  }, [
    setDeviceType
  ]);

  return (
    <ScrollToTop>
      <div id="Hello">
        <Helmet
          defaultTitle={process.env.REACT_APP_NAME}
          titleTemplate={`%s | ${process.env.REACT_APP_NAME}`}
        >
          <title>Hello</title>
          <meta property="og:title" content="Hello" />
          <meta property="og:type" content="website" />
        </Helmet>
        <Layout>
          <Routes />
        </Layout>
      </div>
    </ScrollToTop>
  )
};

const mapStateToProps = state => {
  return {
    alert: state.alert,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setDeviceType: deviceType => dispatch({ type: 'SET_DEVICE_TYPE', value: deviceType })
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
