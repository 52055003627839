import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAuth } from 'components/provider/Auth';
import { withRouter } from 'react-router';

import Util from 'utilities';

import View from './view.js';

class NavigationContainer extends Component {
  render() {
    const { location, ...other } = this.props;

    return <View defaultSelectedKey={location.pathname} {...other} />;
  }
}

const mapStateToProps = state => {
  return {
    isPhone: Util.isPhone(state),
  };
};

export default withRouter(
  withAuth(
    connect(
      mapStateToProps
    )(NavigationContainer)
  )
);
