/* eslint-disable */
// eslint has a bug around false positives in for loops
// @flow
import React from 'react';
import moment from 'moment';
// import { Deserializer } from ('jsonapi-serializer');
import { compact as _compact, each as _each, filter as _filter, flatMapDeep as _flatMapDeep, get as _get, groupBy as _groupBy, isEmpty as _isEmpty, isArray as _isArray, map as _map, pick as _pick, reduce as _reduce, snakeCase as _snakeCase, split as _split, toPairs as _toPairs } from 'lodash-es';

import _λcompact from 'lodash/fp/compact';
import _λflow from 'lodash/fp/flow';
import _λfromPairs from 'lodash/fp/fromPairs';
import _λgroupBy from 'lodash/fp/groupBy';
import _λmap from 'lodash/fp/map';
import _λreplace from 'lodash/fp/replace';
import _λsortBy from 'lodash/fp/sortBy';
import _λsplit from 'lodash/fp/split';

import { config as apiConfig } from 'api';

import getSumOfUnsoldSponsorshipsForEvents from './getSumOfUnsoldSponsorshipsForEvents';
import getSumOfEventsForPropertiesInMonth from './getSumOfEventsForPropertiesInMonth';
import emailValidator from 'email-validator';

import TextMuted from 'components/shared/TextMuted'; // madness

const url = {
  query: param => {
    return _λflow(
      _λreplace('?', ''), // a=b454&c=dhjjh&f=g6hksdfjlksd
      _λsplit('&'), // ["a=b454","c=dhjjh","f=g6hksdfjlksd"]
      _λmap(keyValString => _split(keyValString, '=', 2)), // [["a","b454"],["c","dhjjh"],["f","g6hksdfjlksd"]]
      _λfromPairs // {"a":"b454","c":"dhjjh","f":"g6hksdfjlksd"}
    )(window.location.search)[param];
  },
  serialize: obj => {
    const str = [];
    for (let p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    return str.join('&');
  }
};

const basicSorter = key => (a, b) => {
  if (a[key] < b[key]) return 1;
  if (a[key] > b[key]) return -1;
  return 0;
};

const file = {
  basename: (str, sep = '/') => str.substr(str.lastIndexOf(sep) + 1)
};

const s3ProxyUrl = (bucketName, filename) => {
  if (bucketName) return `${apiConfig.API_HOST}/s3/${bucketName}/${filename}`;
  return `${apiConfig.API_HOST}/s3/${filename}`;
};

const sorter = {
  date: key => (a, b) => moment(_get(a, key)).diff(moment(_get(b, key))),
  number: key => (a, b) => _get(a, key, 0) - _get(b, key, 0),
  string: key => (a, b) => {
    const aName = _get(a, key, '').toLowerCase();
    const bName = _get(b, key, '').toLowerCase();
    if (aName > bName) return 1;
    if (aName < bName) return -1;
    return 0;
  }
};

// JSON API resource utilities

const attachIncludesToResource = (resource, state) => {
  const resourceWithAttachedIncludes = Object.assign({}, resource);

  if (_get(resource, 'relationships')) {
    _each(resource.relationships, (value, key) => {
      if (!_isArray(value.data) && _get(value, 'data.id')) {
        const type = pluralizeKey(_snakeCase(_get(value, 'data.type')));
        const include = _get(state, `resources.${type}[${value.data.id}]`);
        resourceWithAttachedIncludes[key] = attachIncludesToResource(include, state);
      } else if (_isArray(value.data)) {
        const ids = _λflow(_λmap(reference => _get(reference, 'id')), _λcompact)(value.data);
        const type = pluralizeKey(_snakeCase(_get(value, 'data[0].type')));
        const includes = _pick(_get(state, `resources.${type}`, {}), ids);

        resourceWithAttachedIncludes[key] = includes;
      }
    });
  }

  return resourceWithAttachedIncludes;
};

const resourceArrayToObject = resources => {
  return _reduce(
    resources,
    (memo, resource) => {
      memo[resource.id] = resource;
      return memo;
    },
    {}
  );
};

const storeIncludes = (state, resources) => {
  const resourcesByType = _groupBy(resources, resource => pluralizeKey(_snakeCase(resource.type)));
  const newState = Object.assign({}, state);

  _each(resourcesByType, (value, key) => {
    newState[key] = Object.assign({}, _get(newState, key, {}), resourceArrayToObject(value));
  });

  return newState;
};

const pluralizeKey = key => {
  if (key.match(/s$/)) {
    return key;
  } else if (key.match(/y$/)) {
    return `${key.substring(0, key.length - 1)}ies`;
  } else {
    return `${key}s`;
  }
};

const renderCurrency = (value, forceCents) => {
  if (typeof value === 'undefined' || value === null) return '';
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    useGrouping: true,
    currency: 'USD',
    minimumFractionDigits: forceCents ? 2 : 0,
    maximumFractionDigits: forceCents ? 2 : 0
  }).format(value);
};

// The API often returns values as strings like "340.00" or "-14099.04".
// We need to format them as dollar amounts like "$340.00" or "-$14,099.04".
export const formatStringValueAsDollarAmount = value => {
  return `$${parseFloat(value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`; // 1234567.89 => 1,234,567.89
}

const renderAbbrCurrency = (value) => {
  return Math.abs(value) > 999 ? `$${ Math.sign(value)*((Math.abs(value)/1000).toFixed(1)) }K` : `$${ Math.sign(value)*Math.abs(value) }`
};

const renderCurrencyForInput = value => {
  const floatValue = parseFloat(value);
  const prefix = floatValue < 0 ? '-$' : '$';
  const valueAsString = value.toString();
  const hasAPeriod = valueAsString.indexOf('.') !== -1;
  const endsWithAPeriod = valueAsString[valueAsString.length - 1] === '.';
  const endsWithOneDecimalPlace = valueAsString[valueAsString.length - 2] === '.';
  if (endsWithAPeriod || endsWithOneDecimalPlace) return prefix + value;
  if (hasAPeriod) return renderCurrency(value, true);
  return value;
};

const isLoggedIn = state => _get(state, 'auth.token') !== undefined;

const isPhone = state => _get(state, 'device.type') === 'PHONE';

const isStaff = state => _get(state, 'auth.me.auth_user.attributes.is_staff') === true;

const isCreator = state => _get(state, 'auth.me.type') === 'User' && _get(state, 'auth.me.auth_user.attributes.is_staff') === false;

const isReadOnlyCreator = state => _get(state, 'auth.me.attributes.read_only') === true;

const isSponsor = state => _get(state, 'auth.me.type') === 'SponsorUser';

const isFetchingCurrentUser = state => _get(state, 'auth.isFetchingCurrentUser') === true;

const hashColorFromString = (str = '', darkColorsOnly = true) => {
  let hash = 0;
  
  // generate hash from the input string
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  
  if (!darkColorsOnly) {
    // original RGB-based color generation
    hash = hash & 0x00ffffff;
    let r = (hash >> 16) & 0xFF;
    let g = (hash >> 8) & 0xFF;
    let b = hash & 0xFF;
    
    const toHex = (c) => {
      const hex = c.toString(16).toUpperCase();
      return hex.length === 1 ? '0' + hex : hex;
    };
    
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  } else {
    // hsl-based dark color generation
    const hue = Math.abs(hash) % 360; // this ensures the hue is within [0, 360)
    
    const saturation = 60; // percentage
    const lightness = 50;  // percentage (lower value for darker colors)
    
    // convert hsl to hex color
    const hslToHex = (h, s, l) => {
      s /= 100;
      l /= 100;
      
      const c = (1 - Math.abs(2 * l - 1)) * s;
      const x = c * (1 - Math.abs((h / 60) % 2 - 1));
      const m = l - c / 2;
      let r, g, b;
      
      if (h >= 0 && h < 60) { r = c; g = x; b = 0; }
      else if (h >= 60 && h < 120) { r = x; g = c; b = 0; }
      else if (h >= 120 && h < 180) { r = 0; g = c; b = x; }
      else if (h >= 180 && h < 240) { r = 0; g = x; b = c; }
      else if (h >= 240 && h < 300) { r = x; g = 0; b = c; }
      else { r = c; g = 0; b = x; }
      
      r = Math.round((r + m) * 255);
      g = Math.round((g + m) * 255);
      b = Math.round((b + m) * 255);
      
      const toHex = (c) => {
        const hex = c.toString(16).toUpperCase();
        return hex.length === 1 ? '0' + hex : hex;
      };
      
      return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    };
    
    return hslToHex(hue, saturation, lightness);
  }
};

// Environment utilities
const isProduction = () => {
  return window.location.hostname === 'hello.standard.tv';
};

const viewportSize = () => ({
  width: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
  height: Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
});

const viewportMobile = () => viewportSize().width <= 540;

const getCopyVarsFromSponsorhip = sponsorshipWithSponsor => {
  let copyPerShowVarsJson = _get(sponsorshipWithSponsor, `sponsor.attributes.copy_per_show_vars`);
  if (copyPerShowVarsJson === undefined || copyPerShowVarsJson === '') {
    copyPerShowVarsJson = '{}';
  }

  const copyPerShowVars = JSON.parse(copyPerShowVarsJson);
  const vars = _get(copyPerShowVars, _get(sponsorshipWithSponsor, 'event.property.id'), {});

  vars.url = _get(sponsorshipWithSponsor, 'attributes.custom_link_url') || vars.url || _get(sponsorshipWithSponsor, 'sponsor.attributes.copy_link_url') || '';

  vars.code = _get(sponsorshipWithSponsor, 'attributes.custom_code') || vars.code || '';

  return vars;
};

const getIconForUrl = url => {
  return 'https://plus.google.com/_/favicon?domain_url=' + url;
};

const gapi = window.gapi;
const isDevEnv = process.env.NODE_ENV === 'development' ? true : false;
const loadClientWhenGapiReady = script => {
  if (isDevEnv) console.log('Trying To Load Client!');
  if (isDevEnv) console.log(script);
  if (script.getAttribute('gapi_processed')) {
    if (isDevEnv) console.log('Client is ready! Now you can access gapi. :)');
    if (window.location.hostname === 'localhost') {
      gapi.client.load('http://localhost:8080/_ah/api/discovery/v1/apis/metafields/v1/rest').then(
        response => {
          if (isDevEnv) console.log('Connected to metafields API locally.');
        },
        function(err) {
          if (isDevEnv) console.log('Error connecting to metafields API locally.');
        }
      );
    }
  } else {
    if (isDevEnv) console.log("Client wasn't ready, trying again in 100ms");
    setTimeout(() => {
      loadClientWhenGapiReady(script);
    }, 100);
  }
};

const initGapi = () => {
  if (isDevEnv) console.log('Initializing GAPI...');
  if (isDevEnv) console.log('Creating the google script tag...');

  const script = document.createElement('script');
  script.onload = () => {
    if (isDevEnv) console.log('Loaded script, now loading our api...');
    // Gapi isn't available immediately so we have to wait until it is to use gapi.
    loadClientWhenGapiReady(script);
  };
  script.src = 'https://apis.google.com/js/api.js';

  document.body.appendChild(script);
};

const createAndAppendIconLink = (type, size) => {
  const folder = type === 'icon' ? 'transparent' : 'square';
  const link = document.createElement('link');
  link.rel = type;
  link.sizes = `${size}x${size}`;
  link.href = `${process.env.PUBLIC_URL}/icons/${process.env.NODE_ENV}/${folder}/${size}x${size}.png`;
  if (type === 'icon') link.type = 'image.png';
  document.head.appendChild(link);
};

export const checkIsSponsorshipADraft = sponsorship => {
  const insertionOrder = _get(sponsorship, 'insertion_order', null);
  if (insertionOrder) {
    const insertionOrderDate = insertionOrder.created_at;
    const createdAfterDec12018 = moment(new Date(insertionOrderDate)).isAfter(new Date('2018-12-01'));
    const wasSentToSponsor = !!_get(sponsorship, 'insertion_order.sent_to_sponsor_at', false);
    const isSigned = !!_get(sponsorship, 'insertion_order.signed_at', false);
    return !!createdAfterDec12018 && !wasSentToSponsor && !isSigned;
  } else {
    return false;
  }
};

export const checkShouldSponsorshipBeVisibleToCreator = sponsorship => {
  const insertionOrder = _get(sponsorship, 'insertion_order', null);
  if (insertionOrder) {
    const insertionOrderDate = insertionOrder.created_at;
    const createdAfterDec12018 = moment(new Date(insertionOrderDate)).isAfter(new Date('2018-12-01'));
    const wasSentToSponsor = !!_get(sponsorship, 'insertion_order.sent_to_sponsor_at', false);
    const isSigned = !!_get(sponsorship, 'insertion_order.signed_at', false);
    return !!createdAfterDec12018 && (wasSentToSponsor || isSigned);
  } else {
    return false;
  }
};

export const redirect = (event, history, url) => {
  if (event.metaKey === true) {
    window.open(url, '_blank');
  } else {
    history.push(url);
  }
};

export function buildQuery(resourceType, id = null, { action, fields = {}, filters = {}, include, page, path, pageSize, prefix, sort } = {}) {
  const data = {};
  if (include) data.include = include.join(',');
  if (page) data.page = page;
  if (pageSize) data.page_size = pageSize;
  if (sort) data.sort = sort;

  _map(filters, (value, key) => (data[`filter[${key}]`] = value));

  _map(fields, (value, key) => (data[`fields[${key}]`] = value.join(',')));

  const requestPath = path || _compact([prefix, resourceType, id, action]).join('/');
  const params = _toPairs(data).map(pair => pair.join('=')).join('&');

  return `${apiConfig.API_HOST}/${requestPath}/${params ? '?' + params : ''}`; // e.g. http://localhost:3001/properties/?page_size=1000&filter[active]=true
};

export function groupEventsByMonth(events, isStaff) {
  const flattenedEvents = _flatMapDeep(events, event => {
    const filteredSponsorships = _filter(event.sponsorships, sponsorship => !!isStaff || !!checkShouldSponsorshipBeVisibleToCreator(sponsorship));
    if (!_isEmpty(filteredSponsorships)) {
      let showDate = true;

      return _map(filteredSponsorships, sponsorship => {
        let name = _get(sponsorship, 'sponsor.name', <TextMuted>Placeholder</TextMuted>);
        const isDraft = checkIsSponsorshipADraft(sponsorship);
        if (isDraft) {
          name += ' (Draft)';
        } else if (sponsorship.placeholder) {
          name += ' (Deal in progress)';
        }
        const data = {
          ...event,
          ...sponsorship,
          ...sponsorship.sponsor,
          propertyName: _get(event, 'property.name', ''),
          name,
          id: sponsorship.id,
          eventId: event.id,
          key: 'sponsorship-' + sponsorship.id,
          displayDate: showDate ? event.date : '',
          price: parseFloat(sponsorship.price),
          sponsored: true
        };

        showDate = false;
        return data;
      });
    } else {
      return {
        id: event.id,
        eventId: event.id,
        key: 'event-' + event.id,
        propertyName: _get(event, 'property.name', ''),
        displayDate: event.date,
        sponsored: false,
        ...event
      };
    }
  });

  const sortedAndGroupedEvents = _λflow(_λsortBy('date'), _λgroupBy(event => moment(event.date).format('YYYY-MM-01')))(flattenedEvents);
  return sortedAndGroupedEvents;
};

export function formatSecondsAsMSS(s) { return(s-(s%=60))/60+(9<s?':':':0')+s }

export function getWeeksInMonth(monthStartDate) {
  const m = moment(monthStartDate);
  const adjustment = m.day();
  const daysInMonth = m.daysInMonth();
  const adjWeeksInMonth = (daysInMonth + adjustment) / 7;
  return Math.ceil(adjWeeksInMonth)
}

const isEmailAddressValid = emailAddress => {
  return emailValidator.validate(emailAddress);
}


export default {
  attachIncludesToResource,
  basicSorter,
  checkIsSponsorshipADraft,
  createAndAppendIconLink,
  file,
  formatSecondsAsMSS,
  formatStringValueAsDollarAmount,
  getCopyVarsFromSponsorhip,
  getIconForUrl,
  getWeeksInMonth,
  hashColorFromString,
  initGapi,
  isCreator,
  isEmailAddressValid,
  isFetchingCurrentUser,
  isReadOnlyCreator,
  isLoggedIn,
  isProduction,
  isSponsor,
  isPhone,
  isStaff,
  loadClientWhenGapiReady,
  pluralizeKey,
  renderCurrency,
  renderAbbrCurrency,
  renderCurrencyForInput,
  resourceArrayToObject,
  s3ProxyUrl,
  sorter,
  storeIncludes,
  url,
  viewportMobile,
  viewportSize,
  getSumOfUnsoldSponsorshipsForEvents,
  getSumOfEventsForPropertiesInMonth
};
