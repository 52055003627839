import { Redirect, Route, withRouter } from 'react-router-dom';
import { withAuth } from 'components/provider/Auth';
import LoginRedirect from '../LoginRedirect';
import PropTypes from 'prop-types';
import React from 'react';

const HomeRoute = ({
  account,
  component: TheComponent,
  render,
  ...props
}) => {
  return (
    <Route
      {...props}
      render={props => {
        if (account === null) return <LoginRedirect {...props} />;
        if (!account?.data?.isSponsor && !account?.data?.isCreator && !account?.data?.isStaff) return null;
        if (account?.data?.isSponsor) return <Redirect to={'/sponsorships'} />;
        if (account?.data?.isCreator) {
          if (account.propertyCount === 0) return null;
          if (account.propertyCount === 1) return <Redirect to={`/schedule/${account.properties[0].slug}#upcoming`} />;
        }
        return <Redirect to={'/schedule'} />
      }}
    />
  );
};

HomeRoute.contextTypes = {
  component: PropTypes.object
};

export default withRouter(withAuth(HomeRoute));
